import axios from "./../../../axios"

export const GET_USER = 'GET_USER'
export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_ALL_USER = 'DELETE_ALL_USER'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const getUsers = (page = 1) => (dispatch) => {
	axios.get(`internal-users?page=${page}`).then((res) => {
		dispatch({
			type: GET_USER,
			payload: res.data.result,
		})
	})
}

export const searchUser = (text, page = 1) => (dispatch) => {
	axios.get(`internal-users/search/${text}?page=${page}`).then((res) => {
		dispatch({
			type: GET_USER,
			payload: res.data.result,
		})
	})
}

export const deleteUser = (id) => (dispatch) => {
	return axios.delete(`internal-users/${id}`).then((res) => {
		dispatch({
			type: DELETE_USER,
			payload: id,
		})
		return res.data;
	})
}

export const createUser = (user) => (dispatch) => {
	return axios.post('internal-users', user).then((res) => res.data)
}

export const updateUser = (user, id) => async () => {
	return axios.post(`internal-users/${id}`, user).then((res) => {
		return res.data;
	})
}
export const changePassword = (user) => async () => {
	return axios.post('update-password', user).then((res) => {
		return res.data;
	})
}

export const checkEmailForgotPassword = (user) => async () => {
	// return user;
	return axios.post('forgot-password', user).then((res) => {
		return res.data;
	})
}

export const checkEmailCode = (user) => async () => {
	return axios.post('verify-password-otp', user).then((res) => {
		return res.data;
	})
}

export const resetPassword = (user) => async () => {
	return axios.post('admin-change-password', user).then((res) => {
		return res.data;
	})
}
